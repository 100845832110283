@use "../../media" as *;

.wrapper {
  display: grid;
  grid-template-columns: 1fr max-content;
  width: 75rem;
  align-self: center;
  height: 5rem;
  gap: 1.2rem;
  position: relative;
  max-width: 100%;

  @include respond(600) {
    grid-template-columns: 1fr;
  }

  input {
    width: 100%;
    height: 100%;
    padding: 1.2rem;
    border: none;
    border-radius: 0.8rem;
    background-color: #f7f7f7;
    font-family: inherit;
    font-size: 1.4rem;
  }

  input:focus {
    box-shadow: 0 0 10px 0 #1183d6;
    outline: none;
    transition: all 0.2s;
  }
}

.btn-wrapper {
  display: flex;
  gap: 1.2rem;
  justify-self: center;
}

.search-bar {
  position: relative;
}

.btn-location {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.icon-location {
  height: 3rem;
  width: 3rem;
}

.icon-search {
  position: absolute;
  right: 1.6rem;
  height: 2rem;
  width: 2rem;
  color: #777;
  top: 50%;
  transform: translateY(-55%);
}

.btn-search {
  font-size: 2rem;
  padding: 0 4rem;
}
