.wrapper {
  display: flex;
  flex-direction: column;
  grid-column: 1/4;
}

.card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 4.4rem;
  column-gap:3rem;
}

.detail {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content;
  column-gap: 1.2rem;
}

.icon {
  grid-row: 1/-1;
  align-self: center;
  background-image: linear-gradient(to top left, #8debff, #6cacff);
  color: #333;
  border-radius: 1000rem;
  padding: 1rem;
  height: 4.4rem;
  width: 4.4rem;
}

.label {
  font-size: 1.6rem;
  color: #333;
  text-transform: uppercase;
}

.value {
  color: #555;
}
