.wrapper {
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
}

.today {
  font-weight: 300;
}

.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -3rem;
}

.temp {
  font-size: 6.4rem;
}

.unit {
  font-size: 2.4rem;
}
