@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  height: 100%;
  width: 100%;
  animation: spin 1.5s infinite linear;
}
