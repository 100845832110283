@use "../../media" as *;

.wrapper {
  grid-column: 3/-1;
  display: flex;
  flex-direction: column;
}

.card {
  overflow-x: overlay;
  position: relative;

  @include respond(600) {
    padding: 3.6rem 2.4rem;
  }

  &::-webkit-scrollbar {
    background-color: var(--color-light-2);
    border-radius: 1000rem;
    height: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1000rem;
    background-color: var(--color-blue-dark);
  }
}

.forecast {
  display: grid;
  // padding: 0 2rem;
  min-width: 100%;
  width: max-content;
  grid-template-columns: repeat(5, minmax(max-content, 1fr));

  gap: 2.4rem;
  @include respond(600) {
  }
}
